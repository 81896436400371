.email-sign-container{
    background-color: #fff !important;
    width: 100%;
    display: grid;
    grid-template-columns: 25rem 1fr;

}
.email-sign-form-container{
    min-height: 85vh;
    border-right: 1px solid #eee;
    padding: 2rem;
}
.email-sign-container > div{
    width: 100%;
}
.signature-container {
    display: grid;
    place-items: center;
}
.signature-preview-container{
   width: 400px;
    padding: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #eee;
}
.email-sign-input{
    border-radius: 0 !important;
}

.btn-copy{
    width: 100%;
    border: 0 !important;
    color: #fff !important;
    background: #9942e5 !important;  /* fallback for old browsers */
    /*background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2) !important;   !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #4A00E0, #8E2DE2) !important; !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/

}
.btn-copy:hover{
    background: #8E2DE2 !important;  /* fallback for old browsers */
    /*background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2) !important;   !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #4A00E0, #8E2DE2) !important; !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
}

.btn-copy:active{
    background-color: #a557e8 !important;
}
.

@media screen and (max-width: 976px) {
    .email-sign-container{
        width: 55rem !important;
        padding: 3rem !important;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 891px) {
    .email-sign-container{
        width: 100% !important;
        /*width: 50rem !important;*/
        padding: 3rem !important;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .email-sign-form-container{
        min-height: auto;
        border: 0;
        border-bottom: 1px solid #eee;
        padding: 2rem;
    }
}

@media screen and (max-width: 891px) {
    .email-sign-container{
        width: 100% !important;
        /*width: 50rem !important;*/
        padding: 2rem !important;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .signature-container {
        display: grid;
        place-items: start;
        justify-content: center;
        padding-top: 3rem;
    }


}

@media screen and (max-width: 450px) {
    .signature-preview-container{
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .email-sign-container{
        padding: 1rem !important;
    }

    .signature-container {
        display: grid;
        place-items: start;
        padding-top: 3rem;
    }

    .email-sign-form-container{
        padding: 0.5rem;
    }
}

