body{
  min-height: 100vh ;
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;
  background-color: #fff;
  /*background: #8E2DE2 !important;  !* fallback for old browsers *!*/
  /*background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2) !important;   !* Chrome 10-25, Safari 5.1-6 *!*/
  /*background: linear-gradient(to right, #4A00E0, #8E2DE2) !important; !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
}

*{
  margin: 0;
  padding: 0;
}

.main-header{
  height: 4rem;
  background-color: #8E2DE2;
  display: grid;
  align-items: center;
  padding: 0 2rem;
  color:white;
  font-weight: 500;
  font-size: 1.1rem;
}



.main-footer{
  height: 5rem;
  background-color: #EDE4FF;
  display: grid;
  place-items: center;
  color:#281063;
  font-weight: 400;
  font-size: 1rem;
  padding: 1rem;
  text-align: center;
}
